import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { useContext } from "react"
import { FormularioContext } from ".."
import { mask } from "../../../utils/mask"

export function DadosBasicos() {

    const context = useContext(FormularioContext)

    function atualizarFormulario(event : React.ChangeEvent<HTMLInputElement>) {

        let nome = event.target.name
        let value = event.target.value 

        if(nome === 'A1_CGC') {

            value = mask.maskaraCpfCnpj(value)

            let tpPessoa: "J"|"F" = value?.length > 14 ? "J" : "F"
            
            context?.setFormulario({
                ...context.formulario, ...{ [nome]: value}, A1_PESSOA: tpPessoa
            })

        } else {

            context?.setFormulario({ ...context.formulario, ...{ [nome]: value}})
        }

    }


    

    return (
        <>
        <br/>
        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>CPF/CNPJ <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A1_CGC} type="text" name="A1_CGC" onChange={atualizarFormulario} placeholder="000.000.000-00" />
                <label>digite apenas números</label>
            </div>
            <div className={styles.groupInput}>
                <span>Data de Nascimento <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A1_DTNASC} type="date" name="A1_DTNASC" onChange={atualizarFormulario} placeholder="00/00/0000" />
                <label>digite apenas números</label>
            </div>
        </div>
        <div className={styles.groupInput}>
            <span>Nome <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A1_NOME} type="text" name="A1_NOME" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        { context?.formulario.A1_PESSOA === 'J' &&
        <div className={styles.groupInput}>
            <span>Nome Fantasia <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A1_NREDUZ} type="text" name="A1_NREDUZ" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        }

        {/*  context?.formulario.A1_PESSOA === 'J' &&
        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>Inscrição Estadual <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A1_INSCR} type="text" name="A1_INSCR" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
            <div className={styles.groupInput}>
                <span>Inscrição Municipal <RxDotFilled className={styles.legend} /></span>
                <input  value={context?.formulario.A1_INSCRM} type="text" name="A1_INSCRM" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
        </div>
         */}

        <div className={styles.groupInput}>
            <span>Senha (Preencha apenas se deseja alterar sua senha)<RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A1_ZSENHA} type="password" name="A1_ZSENHA" onChange={atualizarFormulario} placeholder="Senha de Acesso" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>

        <div className={styles.groupInput}>
            <span>Confirmar Senha <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A1_ZSENHAREMEMBER} type="password" name="A1_ZSENHAREMEMBER" onChange={atualizarFormulario} placeholder="Confirmar senha de Acesso" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        </>

            )

}