import styles from './styles.module.css'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../../components/header';

export function Cotacao() {


    const [isOpen, setOpen] = useState(false)


    return (

        <div className={styles.container}>

                <div className={styles.content} >

                        <h2>Preciso Proteger...</h2>

                        <div>
                            <Link to={'/cotacao/R'}>
                                <button>
                                    Minha família
                                </button>
                            </Link>
                            <Link to={'/cotacao/C'}>
                            <button>
                                Meu negócio
                            </button>
                            </Link>
                        </div>
                </div>
        </div>

    )
}