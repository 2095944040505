import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RxDotFilled } from "react-icons/rx";
import { useContext, useEffect, useState } from "react";
import Hamburger from "hamburger-react";
import { ContextRegister } from "../../context";
import { Header } from "../../components/header";

export function ProtegerConfirmacao() {
    const navigate = useNavigate();
    
    const { state, dispatch } = useContext(ContextRegister);

    const currencyFormater =  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

    function handleNext() {
        navigate('/proteger-formulario')
    }

    function getPragas() {
        return state.formacaoPreco.ITENS.filter(item => item.GRUPO === "IP03")
    }

    function getInfoAdicionais() {
        return state.formacaoPreco.ITENS.filter(item => item.GRUPO === "IP04")
    }


    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        if(!state?.formacaoPreco?.VALOR && state?.formacaoPreco?.VALOR === 0) {
            navigate("/home")
        }
    }, [])

    return (

        <div className={styles.container}>

            <Header />

            <div className={styles.content} >

                <div className={styles.cardForm}>

                    <div className={styles.groupCardFormTitle}>
                        <p>Você selecionou proteção contra, {' '} 
                        { getPragas().map(praga => <b key={praga.DESCR}>{praga.DESCR}, </b>)}

                        { getInfoAdicionais().length > 0 && 
                            <>
                        e há presença de {' '}
                        { getInfoAdicionais().map(info => <b key={info.DESCR}>{info.DESCR}, </b>)}
                        no local, </>} com uma assistência técnica de <b>{state.formacaoPreco.ASSISDIAS} DIAS.</b>
                        </p>

                    </div>

                    <div className={styles.valuesPseudoButton}>


                        <p className={styles.valuesPseudoButtonTitle}>Valor do Investimento</p>

                        <div>
                            <p>{ currencyFormater.format(state.formacaoPreco.VALOR)}</p>
                        </div>

                    </div>

                    <div className={styles.submitNext}>
                        <button>Cancelar</button>
                        <button onClick={handleNext}>
                            Confirmar e continuar
                            &nbsp;&nbsp;&nbsp;{' >'}
                        </button>
                    </div>

                </div>
            </div>
        </div>

    )

}