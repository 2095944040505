import styles from "./styles.module.css"
import { useNavigate, useParams } from 'react-router-dom';
import { RxDotFilled } from "react-icons/rx";
import { useContext, useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { AuthFetchJson, url } from "../../api";
import { ContextRegister } from "../../context";
import cep from "cep-promise"
import { Header } from "../../components/header";

export function ProtegerImovel() {
    const navigate = useNavigate();
    const parametros = useParams();
    
    const [detalhesImovel, setDetalhesImovel] = useState<any>([])
    const [pragas, setPragas] = useState<any>([])
    const [infos, setInfos] = useState<any>([])

    const [itens, setItens ] = useState<string[]>([])

    const [assistencia, setAssistencia] = useState(30)

    const [metragem, setMetragem] = useState('')

    const [tabela, setTabela] = useState('')

    const { state, dispatch } = useContext(ContextRegister);

    function selectItem(ZA_ITEM: string) {

        let index = itens.findIndex(i => i === ZA_ITEM)

        if(index === -1) {
            setItens([ ...itens, ZA_ITEM])
        } else {

            let aux = [...itens]

            aux.splice(index, 1)

            setItens(aux)
        }

    }

    function goNextPermission() {
        return pragas.some( (praga: any) => itens.includes(praga.ZA_ITEM) ) &&  (parseInt(metragem)||0) > 0
    }

    async function handleNext(event: React.FormEvent<any>) {

        event.preventDefault()

        let itensAux = [...itens]
        
        if(metragem === "") {
            Swal.fire({
                icon: "error",
                title: "Metragem é obrigatório!",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        let existePragaSelecionada = pragas.some( (praga: any) => itens.includes(praga.ZA_ITEM))


        if(!existePragaSelecionada) {
            Swal.fire({
                icon: "error",
                title: "Selecione pelo menos uma praga alvo!",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        Swal.fire({
            title: 'Aguarde!',
            html: 'Calculando valor do serviço.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });        
        
        detalhesImovel.map((d: any) => {
            if(d.ZA_FAIX1 <= parseInt(metragem) && d.ZA_FAIX2 >= parseInt(metragem)) {
                itensAux.push(d.ZA_ITEM)
            } 
        })


        let body = {

            TABELA: tabela,
            ASSDIAS: `${assistencia}`,
            ITENS: itensAux,
            FPGTO: 'C'
        }


        let response: any  = await AuthFetchJson(url+'COLETAPRECO', {
            body: JSON.stringify(body),
            method: 'POST'
        })

        if(response.sucesso !== 'T') {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao gerar preço, tente novamente ou entre em contato com o suporte!",
            });
            return
        }

        if(Swal.isLoading()) {
            Swal.close()
        }

        response.dados[0]['ASSISDIAS'] = `${assistencia}`
        response.dados[0]['TABELA'] = tabela
        dispatch({
            type: 'formacaoPreco',
            payload: { ...response.dados[0], METRAGEM: metragem }
        })

        navigate('/proteger-confirmacao/'+btoa(response.dados[0]['VALOR']))
    }


    async function buscaEndereco(cepStr: string, tabelaPraca: string) {

        try {
            let dados = await cep(cepStr);
            let aux = { ...state.agendamento }
    
            aux.CEP = cepStr
            aux.bairro = dados.neighborhood
            aux.estado = dados.state
            aux.municipio = dados.city
            aux.endereco = dados.street
            aux.tabela = tabelaPraca
    
            dispatch({
                type: 'agendamento',
                payload: aux
            })

        }catch(error) {

            let aux = { ...state.agendamento }
            aux.CEP = cepStr
            aux.tabela = tabelaPraca
            dispatch({
                type: 'agendamento',
                payload: aux
            })
        }

    }

    async function getCep() {

        let { value } = await Swal.fire({
            title: 'CEP',
            text: 'Digite o CEP de onde o serviço será executado!',
            icon: 'info',
            confirmButtonText: 'CONFIRMAR',
            showCloseButton: true,
            input: 'text',
            didClose: () => {
                if(!value) {
                    
                    navigate(-1)
                }
            }
        })

        coletaFiltros(value, parametros.tipo ?? '')
    }

    async function coletaFiltros(CEPIMOVEL: string, TPIMOVEL: string) {

        if (!CEPIMOVEL || CEPIMOVEL.length < 5){

            if(CEPIMOVEL !== undefined ) {
                getCep()
            }

            return
        }

        Swal.fire({
            title: 'Aguarde!',
            html: 'Buscando dados da praça.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });

        try {

            let response: any = await AuthFetchJson(`${url}COLETAFILTROS`, {

                body: JSON.stringify({
                    CEPIMOVEL,
                    TPIMOVEL
                }),
                method: 'POST'
            })

            if(response.sucesso !== "T" || response?.dados?.length === 0) {

                let mensagem = "";

                if(response?.dados?.length === 0) {

                    mensagem = "Está localidade ainda não é atendida"
                    try {
                        AuthFetchJson(`${url}ENVIANOTIFICACAO`, {
                            method: "POST",
                            body: JSON.stringify({
                                A1_COD: state.usuario.A1_COD,
                                A1_LOJA: state.usuario.A1_LOJA,
                                A1_CEP: CEPIMOVEL,
                            })
                        })

                    }catch(error) {
                        console.log(error)
                    }

                } else {

                    mensagem = "Erro ao gerar preço, tente novamente ou entre em contato com o suporte! " + response.mensagem
                }

                await Swal.fire({
                    position: "top-end",
                    icon: "info",
                    title: mensagem,
                });

                getCep()
                return
            }

            setDetalhesImovel(response?.dados[1]["IP02-METRAGEM"])
            setPragas(response?.dados[2]["IP03-PRAGAS"])
            setInfos(response?.dados[3]["IP04-CONDICIONANTES"])
            setTabela(response?.dados[0]["TABELA"])
            
            buscaEndereco(CEPIMOVEL, response?.dados[0]["TABELA"])

        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao buscar praça",
                showConfirmButton: false,
                timer: 1500
            });
        } finally {

            if(Swal.isLoading()) {
                Swal.close()
            }
        }
    }

    useEffect(() => {
        getCep()
    }, [])

    return (

        <div className={styles.container}>

            <Header />

            <div className={styles.content} >

                <div className={styles.cardForm}>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>
                    <form onSubmit={handleNext}>
                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Detalhes do imóvel</p>
                            <RxDotFilled className={styles.legend} />

                        </div>

                        <div className={styles.groupInput}>
                            <label>Tamanho do imóvel em m²</label>
                            <input min={1} placeholder="m²" type="number"  onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setMetragem(event.target.value)}/>

                        </div>

                    </div>

                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Proteção Contra</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupContainer}>

                            {
                                pragas.map((p: any) => (

                                    <div key={p.ZA_ITEM} className={styles.radioGroup}>
                                        <input title="praga" type="checkbox" value={p.ZA_ITEM} name="praga" onClick={() => selectItem(p.ZA_ITEM)} />
                                        <span>&nbsp; {p.ZA_DESCR}</span>
                                    </div>
                                ))
                            }


                        </div>
                    </div>

                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Condicionantes Sensíveis</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupContainer}>

                            {
                                infos.map((i: any) => 
                                    <div key={i.ZA_DESCR} className={styles.radioGroup}>
                                        <input title="info" type="checkbox" value={i.ZA_ITEM} name="info" onClick={() => selectItem(i.ZA_ITEM)}  />
                                        <span>&nbsp;  {i.ZA_DESCR}</span>
                                    </div>
                                )
                            }

                        </div>
                    </div>


                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Assistência técnica</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupContainer}>
                            <div className={styles.radioGroup}>
                                <input title="assdias30" type="radio" value='30' name="ASSDIAS" onChange={_ => setAssistencia(30)} checked={assistencia === 30} />
                                <span>&nbsp; 30 dias</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input title="assdias60" type="radio" value='60' name="ASSDIAS" onChange={_ => setAssistencia(60)} checked={assistencia === 60} />
                                <span>&nbsp; 60 dias</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input title="assdias90" type="radio" value='90' name="ASSDIAS"  onChange={_ => setAssistencia(90)} checked={assistencia === 90}/>
                                <span>&nbsp; 90 dias</span>
                            </div>

                        </div>
                    </div>

                    <div className={ goNextPermission() ? styles.submitNextSelected :  styles.submitNext}>
                        <button>
                            <span>Próximo</span>
                            <span>{' >'}</span>
                        </button>
                    </div>

                    </form>

                </div>
            </div>
        </div>

    )

}