import styles from './styles.module.css'
import logo from "../../assets/icon.png"
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export function Header() {


    const navigate = useNavigate()

    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <div className={styles.menu}>

                <img alt="logo" src={logo} />

                <div className={styles.actions}>
                    
                    
                    <button onClick={() => navigate('/home', { replace: true})}>Nova solicitação</button>
                    
                    
                    <button onClick={() => navigate('/servicos', { replace: true})}>Meus Serviços</button>

                    <Link to="/minha-conta">Minha conta</Link>

                    <Link to="/">Sair</Link>

                </div>

                <div className={styles.hamburguer}>

                    <Hamburger
                        toggled={isOpen}
                        toggle={setOpen} />

                </div>

            </div>

            {isOpen &&
                <nav className={styles.navMenu}>
                    <ul>

                        <Link to="/home" style={{textDecoration: "none"}}>
                            <li>
                                Nova Solicitação
                            </li>
                        </Link>
                        <Link to="/servicos" style={{textDecoration: "none"}}>
                        <li>
                            Meus Serviços
                        </li>
                        </Link>
                        <Link to="/minha-conta" style={{textDecoration: "none"}}>
                            <li>
                                Minha Conta
                            </li>
                        </Link>
                        <Link to="/"  style={{textDecoration: "none"}}>
                        <li>
                            Sair
                        </li>
                        </Link>
                    </ul>
                </nav>
            }
        </>
    )
}