import { ChangeEvent, FormEvent, ReactNode, useContext, useEffect, useState } from "react";
import { ContextRegister } from "../../context";
import { STATUS_SERVICO } from "../../constants";
import { Header } from "../../components/header";
import { Modal } from "../../components/modal";
import { BsFiletypeXml } from "react-icons/bs";
import { CiCalendarDate } from "react-icons/ci";
import { FaRegFilePdf } from "react-icons/fa6";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdOutlineStarOutline, MdOutlineStarPurple500 } from "react-icons/md";
import { SlLike } from "react-icons/sl"
import { GrCertificate } from "react-icons/gr";
import { SiAdguard } from "react-icons/si";
import { AuthFetchJson, url } from "../../api";
import styles from "./styles.module.css"
import Swal from "sweetalert2";
import { DateUtils } from "../../utils/dates";

export function Servicos() {
    
    const { state, dispatch } = useContext(ContextRegister)
    const [isOpen, setOpen] = useState(false)
    const [isOpenAssis, setOpenAssis] = useState(false)
    const [servicos, setServicos] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [servicoSelecionado, setServicoSelecionado] = useState<any>(null)
    const formaterCurrency = new Intl.NumberFormat('pt-br', {
        currency: 'BRL',
        style: 'currency'
    })
    const turno: any = {
        "M": "Manhã",
        "T": "Tarde",
        "N": "Noite",
    }
    const [alterarAgenda, setAlterarAgenda] =
        useState<{
            Z1_TURNO: "M" | "T" | "N",
            Z1_TURNORE: "M" | "T" | "N",
            Z1_DTAGEND: string,
            Z1_NUMPV: string,
            Z1_DTRETOR: string,
        }>({
            Z1_TURNO: "M",
            Z1_TURNORE: "M",
            Z1_DTAGEND: "",
            Z1_NUMPV: "",
            Z1_DTRETOR: "",
        })

    const [alteraAssist, setAlteraAssist] = 
        useState<{
            Z1_NUMPV: string,
            Z1_DTASSIS: string,
            Z1_TURNOAS: "M" | "T" | "N",
        }>({
            Z1_NUMPV: "",
            Z1_DTASSIS: "",
            Z1_TURNOAS: "M"
        })
    

    async function buscarServicos() {

        Swal.fire({
            title: 'Aguarde!',
            html: 'Buscando dados dos serviços.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        AuthFetchJson(`${url}COLETAATENDIMENTO`, {
            method: "POST",
            body: JSON.stringify({
                "CLIENTE": state.usuario.A1_COD,
                "LOJA": state.usuario.A1_LOJA,
                "STATU": "2"
            })
        })
            .then((retorno: any) => {
                if (retorno.sucesso !== 'T') {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao buscar os servicos!" + retorno.mensagem,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    return;
                }

                if (Swal.isLoading()) {
                    Swal.close()
                }


                setServicos(retorno.dados)


            })
            .catch(error => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao buscar dados!" + error,
                    showConfirmButton: false,
                    timer: 1500
                });
            })

    }

    async function aceitarAgendamento(servico: any) {

        const confirmacao = await Swal.fire({
            title: `Deseja confirmar visita para ${dateFormater(servico.Z1_DTAGEND)} às ${servico.Z1_HRVISIT}hrs`
        })

        if (confirmacao.isDenied || confirmacao.isDismissed) {
            return;
        }


        Swal.fire({
            title: 'Aguarde!',
            html: 'Confirmando visita!',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        AuthFetchJson(`${url}ACEITAVISITA`, {
            method: "POST",
            body: JSON.stringify({
                DADOS: {

                    "Z1_NUMPV": servico.Z1_NUMPV,
                    "Z1_FILIAL": servico.Z1_FILIAL,
                }
            })
        }).then(retorno => {


                if (retorno.sucesso !== "T") {
                    Swal.fire({
                        title: "Valindando Visita",
                        text: "Erro ao aceitar visita!",
                        icon: "error",
                        timer: 1500,
                        showConfirmButton: false
                    })
                } else {

                    Swal.fire({
                        title: "Valindando Visita",
                        text: "Visita confirmada com sucesso!",
                        icon: "success",
                        timer: 1500,
                        showConfirmButton: false
                    })

                }
            }).catch(error => {
                console.log(error)
                Swal.fire({
                    title: "Valindando Visita",
                    text: "Erro ao aceitar visita!",
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false
                })
            }).finally(() => {
                buscarServicos()
            })

    }

    function handleDetailsAssist(servico: any) {
        Swal.fire({
            title: "Detalhes da assitência",
            html: `
                <div>
                    <div style="display: flex;flex-direction: row; justify-content: space-between; padding: 10px;">
                        <div>
                            <strong>data:</strong> ${DateUtils.formatDtProtheus(servico.Z1_DTASSIS)}
                        </div>
                        <div>
                            <strong>hora:</strong> ${servico.Z1_HRASSIS}
                        </div>
                    </div>
                </div> `
        })

    }
    
    async function avaliacao(indice: number, servico: any, campo: number) {

        //const mensagem = servico.Z1_AVALIAC !== "0" ? "Faça a reavaliaçao do serviço." : "Faça a avaliaçao do serviço."

        const avaliacao = indice + 1

        let aux = { ...servicoSelecionado }

        if (campo === 1) {
            aux.Z1_AVALIAC = avaliacao
        }

        if (campo === 2) {
            aux.Z1_AVALIA2 = avaliacao
        }

        if (campo === 3) {
            aux.Z1_AVALIA3 = avaliacao
        }

        setServicoSelecionado(aux)

        AuthFetchJson(`${url}AVALIAATENDIMENTO`, {
            method: "POST",
            body: JSON.stringify({
                "Z1_NUMPV": servico.Z1_NUMPV,
                "Z1_AVALIAC": `${aux.Z1_AVALIAC}`,
                "Z1_AVALIA2": `${aux.Z1_AVALIA2}`,
                "Z1_AVALIA3": `${aux.Z1_AVALIA3}`
            })
        }).then(retorno => {


                if (retorno.sucesso !== "T") {
                    Swal.fire({
                        title: "Avaliação",
                        text: "Erro ao realizar avaliaçao!",
                        icon: "error",
                        timer: 1500,
                        showConfirmButton: false
                    })
                }
            }).catch(error => {
                console.log(error)
                Swal.fire({
                    title: "Avaliação",
                    text: "Erro ao realizar avaliaçao!",
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false
                })
            })

    }

    async function handleAssist(servico: any) {

        let { isConfirmed, value } = await Swal.fire({
            title: "Assistência",
            html: "Deseja solicitar assistência para seu atendimento? Escreve 'SOLICITAR' no campo abaixo.",
            confirmButtonText: "Confirmar",
            input: "text"
        })

        if (!isConfirmed) {
            return;
        }

        if (value.toUpperCase() === 'SOLICITAR') {

            Swal.fire({
                title: "Solicitando em andamento!",
                html: "Aguarde enquanto estamos fazendo sua solicitação.",
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            AuthFetchJson(`${url}SOLICITAASSIST/${servico.Z1_NUMPV}`)
                .then((retorno: any) => {
                    if (retorno.sucesso !== "T") {
                        Swal.fire("Erro ao fazer solicitação de assistência. " + retorno.mensagem)
                        return;
                    }
                    handleAssistencia(servico)
                }).finally(() => {
                    if (Swal.isLoading()) {
                        Swal.close()
                    }
                })
        } else {

            Swal.fire("Erro ao digitar palavra chave.")
        }

    }

    function mediaAvaliacao(av1: any, av2: any, av3: any) {

        let total = parseInt(`${av1}`) + parseInt(`${av2}`) + parseInt(`${av3}`)

        total = total / 3

        return Math.trunc(total)

    }

    function starts(avaliacao: number): ReactNode[] {


        let whiteStars = 5 - avaliacao

        let fullStars = avaliacao


        let icons: ReactNode[] = []

        for (let i = 0; i < fullStars; i++) {
            icons.push(<MdOutlineStarPurple500 fontSize={18} color="var(--warning-dark)" />)
        }

        for (let i = 0; i < whiteStars; i++) {
            icons.push(<MdOutlineStarOutline fontSize={18} />)
        }

        return icons

    }

    function dateFormater(dataStr: string) {

        if(!dataStr || dataStr === "") {
            return ""
        }
        //dataStr AAAAMMDD
        return new Date(`${dataStr.slice(0, 4)}-${dataStr.slice(4, 6)}-${dataStr.slice(6, 8)} 00:00`).toLocaleString('pr-BR', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })

    }

    function alteraAgendamento(servico: any) {


        
        let dataServico = new Date(`${DateUtils.formatDtProtheusToInput(servico.Z1_DTAGEND)} 23:59:59`)

        let data2Days = new Date()
        data2Days.setDate(data2Days.getDate() + 2)
        data2Days.setHours(23, 59, 59)

        if(dataServico < data2Days) {
            Swal.fire({
                title: "Alteração de agendamento",
                text: "Serviçcos agendados para até 2 dias não são editáveis. Se necessário entre em contato com a IPEST",
                icon: "error",
            })
            return;
        }


        setServicoSelecionado(servico)

        setAlterarAgenda({
            Z1_TURNO: servico.Z1_TURNO,
            Z1_NUMPV: servico.Z1_NUMPV,
            Z1_TURNORE: servico.Z1_TURNORE, 
            Z1_DTAGEND: DateUtils.formatDtProtheusToInput(servico.Z1_DTAGEND),
            Z1_DTRETOR: DateUtils.formatDtProtheusToInput(servico.Z1_DTRETOR),
        })
        setOpen(true);

    }

    function alteraAssistencia(event: FormEvent) {

        event.preventDefault()


        let now = new Date()
        now.setHours(23, 59, 59)

        let selectedDate = new Date(`${alteraAssist.Z1_DTASSIS} 00:00:00`)
        if (now > selectedDate) {
            Swal.fire("Datas menores ou iguais a data atual não são permitidas.")
            return;
        }

        AuthFetchJson(`${url}ALTERAASSIST`, {
            method: "POST",
            body: JSON.stringify({
                ...alteraAssist,
                Z1_DTASSIS: alteraAssist.Z1_DTASSIS.replaceAll('-', ''),

            })
        })
            .then(response => {

                if (response.sucesso !== "T") {
                    Swal.fire("Erro ao cadastrar/alterar assistência.")
                    return
                }

                Swal.fire("Assistência Alterada/Cadastrada com sucesso.")

                buscarServicos()


            }).catch(err => {
                Swal.fire("Erro ao Alterada/Cadastrada A assistência.")

            }).finally(() => {
                setOpenAssis(false)
            })

    }

    function handleAssistencia(service: any) {

        setServicoSelecionado(service)
        setAlteraAssist(
            {
                Z1_NUMPV: service.Z1_NUMPV,
                Z1_DTASSIS: DateUtils.formatDtProtheusToInput(service.Z1_DTASSIS),
                Z1_TURNOAS: service.Z1_TURNOAS?service.Z1_TURNOAS:"M"
            }
        )
        setOpenAssis(true)

    }

    function handleAvaliacao(servico: any) {

        if (servico.Z1_STATUS !== "5") {

            Swal.fire({
                title: "Avaliação",
                text: "Apenas serviços finalizados podem ser avaliados!",
            })

            return;
        }

        setServicoSelecionado(servico)
        setShowModal(true)
    }

    function handleAlterarAgenda(event: ChangeEvent<HTMLInputElement>) {
        setAlterarAgenda(agenda => ({ ...agenda, [event.target.name]: event.target.value }))
    }

    function handleAlteraAssist(event: ChangeEvent<HTMLInputElement>) {
        setAlteraAssist(assis => ({ ...assis, [event.target.name]: event.target.value }))
    }

    function changeAgenda(event: FormEvent<HTMLFormElement>) {

        event.preventDefault()

        let now = new Date()
        now.setHours(23, 59, 59)

        let selectedDate = new Date(`${alterarAgenda.Z1_DTAGEND} 00:00:00`)
        if (now > selectedDate) {
            Swal.fire("Datas menores ou iguais a data atual não são permitidas.")
            return;
        }

        if(alterarAgenda.Z1_DTRETOR !== "") {

            let dataRetornoAux: Date = new Date(`${alterarAgenda.Z1_DTRETOR} 00:00:00`)

            let dataAuxTwoWeeks = new Date(`${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()} 00:00`)
            let dataAuxTwoMonths = new Date(`${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()} 00:00`)

            //verifica se a data tem uma diferença maior que 2 semanas ou maior que 2 meses

            dataAuxTwoWeeks.setDate(selectedDate.getDate() + 15)
            dataAuxTwoMonths.setDate(selectedDate.getDate() + 20)

            if(dataAuxTwoWeeks > dataRetornoAux || dataAuxTwoMonths < dataRetornoAux) {
                Swal.fire("Datas de agendamento e retorno incorretas.")
                return;
            }
        }

        AuthFetchJson(`${url}ALTERAAGENDA`, {
            method: "POST",
            body: JSON.stringify({
                ...alterarAgenda,
                Z1_DTAGEND: alterarAgenda.Z1_DTAGEND.replaceAll('-', ''),
                Z1_DTRETOR: alterarAgenda.Z1_DTRETOR.replaceAll('-', ''),
            })
        })
            .then(response => {

                setOpen(false)

                if (response.sucesso !== "T") {
                    Swal.fire("Erro o alterar agenda.")
                    return
                }

                Swal.fire("Agenda Alterada com sucesso.")

                buscarServicos()


            }).catch(err => {
                Swal.fire("Erro o alterar agenda.")
                setOpen(false)
            })

    }

    useEffect(() => {

        if (showModal === false) {
            buscarServicos()
        }

    }, [showModal])

    return (

        <div className={styles.container}>

            {
                showModal &&
                <Modal setShowModal={setShowModal} title={"Avaliação do Atendimento"}>


                    <h3>Pontualidade</h3>
                    <p className={styles.action}>
                        {
                            starts(servicoSelecionado?.Z1_AVALIAC)
                                .map((star, index) =>
                                (<span
                                    onClick={() => avaliacao(index, servicoSelecionado, 1)}>{star}
                                </span>))}
                    </p>

                    <h3>Asseio e Organização</h3>
                    <p className={styles.action}>
                        {starts(servicoSelecionado?.Z1_AVALIA2).map((star, index) => (<span onClick={() => avaliacao(index, servicoSelecionado, 2)}>{star}</span>))}
                    </p>
                    <h3>Eficiência do Controle</h3>
                    <p className={styles.action}>
                        {starts(servicoSelecionado?.Z1_AVALIA3).map((star, index) => (<span onClick={() => avaliacao(index, servicoSelecionado, 3)}>{star}</span>))}
                    </p>

                </Modal>
            }


            {
                isOpen &&
                <Modal setShowModal={setOpen} title={"Alterar Data do Agendamento"}>
                    <form onSubmit={changeAgenda} style={{width: "100%"}}>
                        <div style={{ display: "flex", height: "auto", flexDirection: "column", justifyContent: "space-around", padding: "15px" }}>

                        { 
                            servicoSelecionado.Z1_DTRETOR !== "" &&
                                <h4 style={{textAlign: "center"}}>Data de retorno deve estar entre 15 e 25 dias após a data da primeira visita.</h4> 
                            }
                            <br/>
                            <h5 style={{ textAlign: "center" }}>Agendado para {dateFormater(servicoSelecionado.Z1_DTAGEND)} - {turno[servicoSelecionado.Z1_TURNO]}</h5>

                            <input title="DATAAGENDA" style={{ height: "35px", margin: "8px 0" }} type="date" value={alterarAgenda.Z1_DTAGEND} name="Z1_DTAGEND" onChange={handleAlterarAgenda} required />
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <label>
                                    <input type="radio" name="Z1_TURNO" onChange={handleAlterarAgenda} value={'M'} checked={alterarAgenda.Z1_TURNO === "M"} />
                                    {'  '}Manhã
                                </label>
                                <label>
                                    <input type="radio" name="Z1_TURNO" onChange={handleAlterarAgenda} value={'T'} checked={alterarAgenda.Z1_TURNO === "T"} />
                                    {'  '}Tarde
                                </label>
                                <label>
                                    <input type="radio" name="Z1_TURNO" onChange={handleAlterarAgenda} value={'N'} checked={alterarAgenda.Z1_TURNO === "N"} />
                                    {'  '}Noite
                                </label>
                            </div>

                            { servicoSelecionado.Z1_DTRETOR !== "" && <>
                            <br/>

                            <h5 style={{ textAlign: "center" }}>Retorno para {dateFormater(servicoSelecionado.Z1_DTRETOR)} - {turno[servicoSelecionado.Z1_TURNORE]}</h5>
                            <input title="DATARETORNO" style={{ height: "35px",  margin: "8px 0" }} type="date" name="Z1_DTRETOR" value={alterarAgenda.Z1_DTRETOR} onChange={handleAlterarAgenda} required />
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <label>
                                    <input type="radio" name="Z1_TURNORE" onChange={handleAlterarAgenda} value={'M'} checked={alterarAgenda.Z1_TURNORE === "M"} />
                                    {'  '}Manhã
                                </label>
                                <label>
                                    <input type="radio" name="Z1_TURNORE" onChange={handleAlterarAgenda} value={'T'} checked={alterarAgenda.Z1_TURNORE === "T"} />
                                    {'  '}Tarde
                                </label>
                                <label>
                                    <input type="radio" name="Z1_TURNORE" onChange={handleAlterarAgenda} value={'N'} checked={alterarAgenda.Z1_TURNORE === "N"} />
                                    {'  '}Noite
                                </label>
                            </div>

                            </>}
                            <br/>
                            <button style={{ fontSize: "15px", color: "white", height: "35px", border: "none", cursor: "pointer", borderRadius: "10px", background: "var(--brand1-medium)" }}>
                                Alterar
                            </button>
                        </div>
                    </form>


                </Modal>
            }

{
                isOpenAssis &&
                <Modal setShowModal={setOpenAssis} title={"Data da Assistência"}>
                    <form onSubmit={alteraAssistencia} style={{width: "100%"}}>
                        <div style={{ display: "flex", height: "auto", flexDirection: "column", justifyContent: "space-around", padding: "15px" }}>

                        { 
                            servicoSelecionado.Z1_DTRETOR !== "" &&
                                <h4 style={{textAlign: "center"}}>Data de Assistência.</h4> 
                            }
                            <br/>
                            
                            <input 
                                title="date" 
                                style={{ height: "35px", margin: "8px 0" }} 
                                type="date" 
                                value={alteraAssist.Z1_DTASSIS} 
                                name="Z1_DTASSIS" 
                                onChange={handleAlteraAssist} 
                                required/>
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <label>
                                    <input type="radio" name="Z1_TURNOAS" onChange={handleAlteraAssist} value={'M'} checked={alteraAssist.Z1_TURNOAS === "M"} />
                                    {'  '}Manhã
                                </label>
                                <label>
                                    <input type="radio" name="Z1_TURNOAS" onChange={handleAlteraAssist} value={'T'} checked={alteraAssist.Z1_TURNOAS === "T"} />
                                    {'  '}Tarde
                                </label>
                                <label>
                                    <input type="radio" name="Z1_TURNOAS" onChange={handleAlteraAssist} value={'N'} checked={alteraAssist.Z1_TURNOAS === "N"} />
                                    {'  '}Noite
                                </label>
                            </div>
                            <br/>
                            <button style={{ fontSize: "15px", color: "white", height: "35px", border: "none", cursor: "pointer", borderRadius: "10px", background: "var(--brand1-medium)" }}>
                                Confirmar
                            </button>
                        </div>
                    </form>


                </Modal>
            }

            <Header />

            <div className={styles.content} >

                <table>

                    <thead>
                        <tr>
                            <th>OS</th>
                            <th>Data Emissão</th>
                            <th>Data Agendada</th>
                            <th>Horário</th>
                            <th>Data Retorno</th>
                            <th>Horário</th>
                            <th>Pragas</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Pagamento</th>
                            <th>Avaliação</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>


                        {
                            servicos.map((servico: any) => (
                                <tr key={servico.Z1_NUMPV}>
                                    <td>{servico.Z1_NUMPV}</td>
                                    <td>{dateFormater(servico.C5_EMISSAO)}</td>
                                    <td>{dateFormater(servico.Z1_DTAGEND)}</td>
                                    <td>{turno[servico.Z1_TURNO]}</td>
                                    <td>{dateFormater(servico.Z1_DTRETOR)}</td>
                                    <td>{turno[servico.Z1_TURNORE]}</td>
                                    <td>{servico?.PRAGAS?.map((p: any) => p.DESCRICAO).join(', ')}</td>
                                    <td>{formaterCurrency.format(servico.VRTOTAL)}</td>
                                    <td>{STATUS_SERVICO[servico.Z1_STATUS]}</td>
                                    <td>
                                        { 
                                        servico.Z1_OKPGTO === 'S' ? <b color="var(--positive-medium)">PAGO</b> 
                                            : 
                                        <a style={{color: "var(--negative-medium)"}} href={servico.Z1_URLPGT}>Aguardando</a>
                                        } 
                                    </td>
                                    <td className={styles.action}>
                                        {starts(mediaAvaliacao(servico.Z1_AVALIAC, servico.Z1_AVALIA2, servico.Z1_AVALIA3)).map((star, index) => (<span onClick={() => handleAvaliacao(servico)}>{star}</span>))}</td>

                                    <td className={styles.action} style={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "space-around" }}>
                                        {servico.Z1_STATUS === "3" && <SlLike onClick={() => aceitarAgendamento(servico)} title="Confirmar visita" />}
                                        {servico.Z1_STATUS <= "3" && <CiCalendarDate size={21} color="var(--negative-medium)" onClick={() => alteraAgendamento(servico)} title="Alterar data da visita" />}
                                        {servico.Z1_STATUS === "5" && <a title="certificado atendimento" href={`${url}BAIXACERTATEND/${servico.Z1_NUMPV}/FATURA.PDF`} download> <LiaFileInvoiceDollarSolid color="var(--positive-dark)" title="Baixar fatura" /> </a>}
                                        {servico.Z1_STATUS === "5" && <a title="certificado atendimento" href={`${url}BAIXACERTATEND/${servico.Z1_NUMPV}/CERTIFICADO.PDF`} download> <GrCertificate color="var(--warning-dark)" title="Baixar Certificado" /></a>}
                                        {servico.Z1_STASSIS === "R" && <a title="certificado assistência" href={`${url}BAIXACERTASSIST/${servico.Z1_NUMPV}/CERTIFICADOASSIST.PDF`} download> <GrCertificate color="var(--warning-dark)" title="Baixar Certificado Assistência" /></a>}
                                        {servico.Z1_STRETOR === "R" && <a title="certificado de retorno" href={`${url}BAIXACERTRETORNO/${servico.Z1_NUMPV}/CERTIFICADORETORNO.PDF`} download> <GrCertificate color="var(--warning-dark)" title="Baixar Certificado de Retorno" /></a>}
                                        {servico.Z1_STATUS === "5" && <a title="danfe" href={`${url}BAIXANOTAATEND/${servico.Z1_NUMPV}/NOTA.PDF`} download> <FaRegFilePdf color="var(--blueberry)" title="Baixar DANFE" /></a>}
                                        {servico.Z1_STATUS === "5" && <a title="xml nfe" href={`${url}BAIXANOTAATEND/${servico.Z1_NUMPV}/NOTA.XML`} download> <BsFiletypeXml color="var(--information-dark)" title="Baixar XML" /></a>}
                                        {
                                            servico.Z1_STATUS === "5" &&
                                            servico.Z1_STASSIS === "N" &&
                                            <SiAdguard onClick={() => handleAssist(servico)} color="var(--negative-lighttest)" title="Solicitar Assistência" />
                                        }

{
                                            servico.Z1_STATUS === "5" &&
                                            servico.Z1_STASSIS === "S" &&
                                            <SiAdguard onClick={() => handleAssistencia(servico)} color="var(--negative-lighttest)" title="Alterar Assistência" />
                                        }

                                        {
                                            servico.Z1_STASSIS === "R" &&
                                            <SiAdguard onClick={() => handleDetailsAssist(servico)} color="var(--negative-lighttest)" title="Detalhes da Assistência" />
                                        }

                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>

    )

}