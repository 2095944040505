import styles from './styles.module.css'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../../components/header';

export function Finalizado() {


    const [isOpen, setOpen] = useState(false)


    return (

        <div className={styles.container}>

            <Header />

            <div className={styles.finalizado}>

                <h2>Compra finalizada</h2>

                <p>Parabéns por adquirir o serviço de dedetização do Ipest!
                    <br />
                    Acompanhe o processo através do sistema.

                </p>

            </div>

                
        </div>

    )
}