import { useReducer } from 'react';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";

import 'react-calendar/dist/Calendar.css';

import { Login } from './telas/login';
import { Home } from './telas/home/indext';
import { Relembrar } from './telas/relembrar';
import { Cadastro } from './telas/cadastro';
import { Proteger } from './telas/proteger';
import { ProtegerImovel } from './telas/proteger-imovel';
import { ProtegerConfirmacao } from './telas/proteger-confirmacao';
import { ProtegerFormulario } from './telas/proteger-formulario';
import { CadastroFormulario } from './telas/cadastro-formulario';
import { defaultValues, ContextRegister, defaultContextType } from './context/index'
import { reducerDefault } from './reducer/index'
import { Servicos } from './telas/servicos';
import { MinhaConta } from './telas/minha-conta';
import { Cotacao } from './telas/cotacao/indext';
import { CotacaoTipo } from './telas/cotacao-tipo';
import { CotacaoImovel } from './telas/cotacao-imovel';
import { CotacaoConfirmacao } from './telas/cotacao-confirmacao';
import { Finalizado } from './telas/finalizado/indext';
import { CheckoutPagamento } from './telas/checkout-pagamento';



function App() {
  
  function getValues(): defaultContextType {



    let usuario: any =  localStorage.getItem("usuario");

    if(usuario === null) {
      return defaultValues
    }

    usuario = JSON.parse(usuario)




      return {
        ...defaultValues, ...{ usuario: usuario}
      }
    
  } 
  
  const [state, dispatch] = useReducer(reducerDefault, getValues());

  return (
    <BrowserRouter>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

        <ContextRegister.Provider value={{state, dispatch}}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/relembrar" element={<Relembrar />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/proteger/:tipo" element={<Proteger />} /> 
            <Route path="/proteger-imovel/:tipo" element={<ProtegerImovel />} />
            <Route path="/proteger-confirmacao/:valor" element={<ProtegerConfirmacao />} />
            <Route path="/proteger-formulario" element={<ProtegerFormulario />} />
            <Route path="/cadastro-formulario" element={<CadastroFormulario />} />
            <Route path="/servicos" element={<Servicos />} />
            <Route path="/minha-conta" element={<MinhaConta />} />
            <Route path="/cotacao" element={<Cotacao />} />
            <Route path="/cotacao/:tipo" element={<CotacaoTipo />} /> 
            <Route path="/cotacao-imovel/:tipo" element={<CotacaoImovel />} />
            <Route path="/cotacao-confirmacao/:valor" element={<CotacaoConfirmacao />} />
            <Route path="/finalizado" element={<Finalizado />} />
            <Route path="/checkout" element={<CheckoutPagamento />} />
          </Routes>
        </ContextRegister.Provider>
    </BrowserRouter>
  );
}

export default App;
