import styles from "./styles.module.css"
import { Link, useNavigate, useParams  } from 'react-router-dom';
import { useEffect, useState } from "react";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { Header } from "../../components/header";
import { IoIosArrowBack } from "react-icons/io";
export function CotacaoTipo() {

    const navigate = useNavigate();
    const [tiposImoveis, setTiposImoveis] = useState([])
    const params = useParams();
    

    useEffect(() => {
    

        Swal.fire({
            title: 'Aguarde!',
            html: 'Buscando dados dos tipos de imóveis.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        AuthFetchJson(`${url}TIPOSIMOVEIS`)
        .then( (retorno:any) => {
            if(retorno.sucesso !== 'T') {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao buscar dados!" + retorno.mensagem,
                    showConfirmButton: false,
                    timer: 1500
                  });
                return;
            }

            if(Swal.isLoading()) {
                Swal.close()
            }


            setTiposImoveis(retorno.dados.filter(
                (t: any) => t.B1_ZCATEGO === params.tipo
            ))


        })
        .catch(error => {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao buscar dados!" + error,
                showConfirmButton: false,
                timer: 1500
              });
        })

    }, [])

return (

    <div className={styles.container}>
            <div className={styles.content} >

                    <Link to={'/cotacao'} className={styles.urlButtonToFamily}>
                        
                        {
                            params.tipo === 'R' ?
                            <p>Minha Familia</p> :
                            <p>Meu Negócio</p>
                        }
                        <IoIosArrowBack className={styles.backIcon} />
                    </Link>

                    <h2>Quero proteger ...</h2>

                    <div className={styles.containerImoveis}>

                        {
                            tiposImoveis.map( (tipo: any) => (

                                <Link key={tipo.B1_COD} to={'/cotacao-imovel/'+tipo.B1_COD}>
                                    <button>
                                        {tipo.B1_DESC}
                                    </button>
                                </Link>
                            ))
                        }
                        
                    </div>
            </div>
    </div>

)

}