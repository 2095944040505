import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { Link, useNavigate } from 'react-router-dom';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { GrGrommet, GrRadialSelected } from "react-icons/gr";
import { useState } from "react";

export function Cadastro() {
    const navigate = useNavigate();
    const [statusPassword, setStatusPassword] = useState(false)
    const [remember, setRemember] = useState(false)


    function login() {
        navigate('/')
    }

    return (
        <div className={styles.container}>

            <div className={styles.titleContainer}>
                <img src={logo} className={styles.logo} />
                <h1 className={styles.title}>Cadastro</h1>
            </div>

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div>


            <div className={styles.content} >

                        <h2>Escolha o tipo de cadastro</h2>
                        
                            <div>
                            <Link to={'/cadastro-formulario'}>
                                <button>
                                    Pessoa física
                                </button>
                            </Link>
                                <button>
                                    Pessoa jurídica
                                </button>
                            </div>
                </div>


        </div>
    )
}