export type clienteType = {
    "A1_PESSOA": "F"|"J"
    "A1_TIPO": "F"
    "A1_CGC": string
    "A1_CEP": string
    "A1_NOME": string
    "A1_END": string
    "A1_BAIRRO": string
    "A1_MUN": string
    "A1_COMPLEM"?: string
    "A1_EST": string
    "A1_TEL"?: string
    "A1_DTNASC"?: string //"AAAAMMDD"
    "A1_EMAIL": string
    "A1_OBSERV"?: string
    "A1_NREDUZ": string
    "A1_ZSENHA"?: string
    "A1_ZSENHAREMEMBER"?: string
    "A1_INSCR"?: string
    "A1_INSCRM"?: string
}

export const clienteDefault: clienteType = {
    "A1_PESSOA": "F",
    "A1_TIPO": "F",
    "A1_CGC": "",
    "A1_CEP": "",
    "A1_NOME": "",
    "A1_END": "",
    "A1_BAIRRO": "",
    "A1_MUN": "",
    "A1_COMPLEM": "",
    "A1_EST": "",
    "A1_TEL": "",
    "A1_DTNASC": "",
    "A1_EMAIL": "",
    "A1_OBSERV": "",
    "A1_NREDUZ": "",
    "A1_ZSENHA": "",
    "A1_INSCR": "",
    "A1_INSCRM": "",
}
