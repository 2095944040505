import { createContext } from "react";






export const defaultValues: defaultContextType = {

    usuario: {
        A1_COD: "",
        A1_LOJA: "01",
        A1_PESSOA: "F",
        A1_CGC: "",
        A1_CEP: "",
        A1_NOME: "",
        A1_NREDUZ: "",
        A1_END: "",
        A1_BAIRRO: "",
        A1_MUN: "",
        A1_COMPLEM: "",
        A1_EST: "",
        A1_TEL: "",
        A1_INSCR: "",
        A1_INSCRM: "",
        A1_CONTATO: "",
        A1_DTNASC: "",
        A1_EMAIL: "",
        A1_OBSERV: "",
        A1_ZSENHA: "",
        RECSA1: ""
    },

    imoveis: [{
        B1_COD: "",
        B1_DESC: ""
    }],

    formacaoPreco: {
        ITENS: [{
            ITEM: "001",
            QUANT: 1,
            VRUNIT: 150,
            DESCR: '',
            GRUPO: ''
        }],
        ASSIS: "30",
        TXCC: 0,
        TXSERV: 0,
        VALOR: 0,
        TABELA: "",
        ASSISDIAS: "30",
        METRAGEM: "0"

    },

    agendamento : {
        CEP: '',
        endereco: '',
        numero: '',
        complemento: '',
        estado: '',
        municipio: '',
        bairro: '',
        pagamento: '',
        tabela: ''
    }

}


export type usuarioType = {

        A1_COD: string
        A1_LOJA: "01"
        A1_PESSOA: "J"|"F"
        A1_CGC: string
        A1_CEP: string
        A1_NOME: string
        A1_NREDUZ: string
        A1_END: string
        A1_BAIRRO: string
        A1_MUN: string
        A1_COMPLEM: string
        A1_EST: string
        A1_TEL: string
        A1_INSCR: string
        A1_INSCRM: string
        A1_CONTATO: string
        A1_DTNASC: string
        A1_EMAIL: string
        A1_OBSERV: string
        A1_ZSENHA: string
        RECSA1: string
}

export type imovelType = {
    B1_COD: string
        B1_DESC: string
}

export type itemType = {
    ITEM: string,
        QUANT: number,
        VRUNIT: number,
        DESCR: string,
        GRUPO: string
}

export type formacaoPrecoType = {
    ITENS: itemType[]
    ASSIS: string
    VALOR: number
    TXCC: number
    TXSERV: number
    TABELA: string
    ASSISDIAS: string
    METRAGEM: string
}

export type agendamentoType = {
    CEP: string,
    endereco: string,
    numero: string,
    complemento: string,
    estado: string,
    municipio: string,
    bairro: string,
    pagamento: string
    tabela: string
}


export type defaultContextType = {
    
    usuario: usuarioType
    imoveis:  imovelType[]
    formacaoPreco: formacaoPrecoType
    agendamento: agendamentoType
}

export type actionsType = 'usuario'|'imoveis'|'formacaoPreco'|'agendamento'


export const ContextRegister = createContext<{
    state: defaultContextType;
    dispatch: React.Dispatch<{ type: actionsType, payload: any}>;
  }>({ state: defaultValues, dispatch: () => null})
