import styles from "./styles.module.css"
import { ChangeEvent, FocusEvent, useState } from "react";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
  } from '../../utils/payment'


type CreditCardType = {
    number: string
    expiry: string
    cvc: string
    name: string
    focus: any
}

export function CheckoutPagamento() {

    const [creditCard, setCreditCard] = useState<CreditCardType>({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = evt.target;


        if (name === 'number') {
            value = formatCreditCardNumber(value)
          } else if (name === 'expiry') {
            value = formatExpirationDate(value)
          } else if (name === 'cvc') {
            value = formatCVC(value)
          }

        setCreditCard((prev) => ({ ...prev, [name]: value }));
    }

    const handleInputFocus = (evt: FocusEvent<HTMLInputElement>) => {
        setCreditCard((prev) => ({ ...prev, focus: evt.target.name }));
    }

    return (

            <div className={styles.creditCardContainer}>

                <Cards
                    placeholders={{name:'Seu nome aqui'}}
                    number={creditCard.number}
                    expiry={creditCard.expiry}
                    cvc={creditCard.cvc}
                    name={creditCard.name}
                    focused={creditCard.focus}
                />

                <div className={styles.creditCardForm}>

                    <div className={styles.formGroup}>
                        <input
                            type="tel"
                            name="number"
                            pattern='[\d| ]{16,22}'
                            maxLength={19}
                            placeholder="Número do Cartão"
                            value={creditCard.number}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nome"
                            value={creditCard.name}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <input
                            type="tel"
                            name="expiry"
                            placeholder="Validade"
                            value={creditCard.expiry}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <input
                            type="tel"
                            name="cvc"
                            placeholder="CVC"
                            value={creditCard.cvc}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                    </div>
                </div>
        </div>

    )

}