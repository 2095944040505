import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import { DadosBasicos } from "./dados-basicos";
import { Endereco } from "./endereco";
import { Contato } from "./contato";
import { clienteType, clienteDefault } from "../../context/cliente-cadastro";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { Header } from "../../components/header";
import { ContextRegister } from "../../context";
import { mask } from "../../utils/mask";

export const FormularioContext = createContext<{formulario: clienteType, setFormulario: Dispatch<SetStateAction<clienteType>>}| null>(null)

export function MinhaConta() {
    const navigate = useNavigate();

    const  [tab, setTab] = useState<'dadosbasicos'|'endereco'|'contato'|'observacao'>('dadosbasicos')

    const [formulario, setFormulario] = useState(clienteDefault);

    const [termo, setTermo] = useState(false);

    const { state, dispatch } = useContext(ContextRegister);

    function login() {
        navigate('/')
    }

    function selectedTab() {

        switch(tab) {

            case 'dadosbasicos':
                return <DadosBasicos/>
            case 'endereco':
                return <Endereco/>
            case 'contato':
                return <Contato/>
            default: 
                return <DadosBasicos/>

        }

    }

    function handleNext() {

        if(tab === "dadosbasicos") {
            setTab("endereco") 
            return
        }

        if(tab === "endereco") {
            setTab("contato") 
            return
        }
        
        if(tab === "contato") {

            let aux = {...formulario}

            aux.A1_DTNASC = aux.A1_DTNASC?.replaceAll('-', '')
            aux.A1_CGC = aux.A1_CGC?.replace(/\D/g, "")
            aux.A1_NREDUZ = aux.A1_NOME.substring(0, 20)

            if(aux.A1_ZSENHA === "" || aux.A1_ZSENHAREMEMBER !== aux.A1_ZSENHA) {
                delete aux.A1_ZSENHA
                delete aux.A1_ZSENHAREMEMBER
            }

            Swal.fire({
                title: 'Aguarde!',
                html: 'Atualizaçao de dados de cadastro!',// add html attribute if you want or remove
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });


            AuthFetchJson(url+ 'ALTERACLIENTE', {
                body: JSON.stringify( { dados: aux } ),
                method: 'POST'
            })
            .then( (retorno: any) => {
                if(retorno.sucesso === 'T') {

                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Cadastro atualizado com sucesso!",
                        showConfirmButton: false,
                        timer: 1500
                      });
                    navigate('/minha-conta')

                } else {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao atualizar seus dados, " + retorno.mensagem,
                        showCancelButton: false
                    });
                }


            })
            .catch((error) => {

                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao atualizar seus dados!" + error.message,
                    showConfirmButton: false,
                });
            }).finally(() => {
                if(Swal.isLoading()) {
                    Swal.close()
                }
            })

        }

    }

    useEffect(() => {



        AuthFetchJson(`${url}COLETACLIENTE`, {
            method: "POST",
            body: JSON.stringify({
                A1_COD : state.usuario.A1_COD,
                A1_LOJA: "01"
            })
        })
            .then( (retorno: any) => {

                if(retorno.sucesso !== "T") {
                    Swal.fire("Erro ao buscar dados de cadastro.")
                    return
                }

                setFormulario( { ...retorno.dados[0], A1_CGC: mask.maskaraCpfCnpj(retorno.dados[0].A1_CGC), A1_DTNASC: retorno.dados[0].A1_DTNASC.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3") })

            })


    }, [])

    return (
        <div className={styles.container}>

            <Header/>


            <div className={styles.content} >

                <div className={styles.cardForm}>
                    <nav className={styles.menuSup}>
                        <p 
                            onClick={() => setTab('dadosbasicos')}
                            className={tab === 'dadosbasicos' ? styles.menuSupSelected : ''}>Dados Básicos</p>
                        <p 
                            onClick={() => setTab('endereco')}
                            className={tab === 'endereco' ? styles.menuSupSelected : ''}>Endereço</p>
                        <p 
                            onClick={() => setTab('contato')}
                            className={tab === 'contato' ? styles.menuSupSelected : ''}>Contato</p>
                    </nav>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>

                    <FormularioContext.Provider value={{formulario, setFormulario}}>
                    {
                        selectedTab()
                    }
                    </FormularioContext.Provider>
                
                    <hr />

                    
                    <div className={tab === "contato" ? styles.submitNextSelected : styles.submitNext}>
                        <button onClick={handleNext}>
                            <span>Confirmar e atualizar</span>
                            <span>{' > '}</span>
                        </button>
                    </div>

                </div>

            </div>


        </div>
    )
}