import React from 'react'
import { defaultContextType, actionsType} from  '../context/index'


export function reducerDefault(state: defaultContextType, action: {type:actionsType, payload: any }) {


    switch (action.type) {


        case 'agendamento':


            return {
                ...state,
                agendamento: action.payload
            }
        case 'formacaoPreco':


            return {
                ...state,
                formacaoPreco: action.payload
            }
        
        case 'imoveis':


            return {
                ...state,
                imoveis: action.payload
            }
            
        case 'usuario':


            return {
                ...state,
                usuario: action.payload
            }
        

        default:
            
            return state

    }

}