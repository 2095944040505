import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { ChangeEvent, useContext, useState } from "react"
import { FormularioContext } from ".."

export function DadosBasicos() {

    const context = useContext(FormularioContext)

    function atualizarFormulario(event : React.ChangeEvent<HTMLInputElement>) {

        let nome = event.target.name
        let value = `${event.target.value}`.toUpperCase()

        if(nome === 'A1_CGC') {

            value = maskaraCpfCnpj(value)

            let tpPessoa: "J"|"F" = value?.length > 14 ? "J" : "F"
            
            context?.setFormulario({
                ...context.formulario, ...{ [nome]: value}, A1_PESSOA: tpPessoa
            })

        } else {

            context?.setFormulario({ ...context.formulario, ...{ [nome]: value}})
        }

    }


    function maskaraCpfCnpj(v: string) {

        v = v.replace(/\D/g, "")  

        v = v.length > 11 ? 
            maskaraCnpj(v) : maskaraCpf(v)

        return v

    }

    function maskaraCpf(v: string): string {
        v= v.replace(/(\d{11})(\d)/, "$1") //remove numeros que passarem da qunatidade de caracteres do cpf
        v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
        return v
    }

    function maskaraCnpj(v: string): string {
        v= v.replace(/(\d{14})(\d)/, "$1") //remove numeros que passarem da qunatidade de caracteres do cpf
        v = v.replace(/(\d{2})(\d)/, "$1.$2")       //Coloca um ponto entre o segundo e o terceiro dígito
        v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o quinto e o sexto dígito
        v = v.replace(/(\d{3})(\d)/, "$1\/$2")       //Coloca uma barra entre o oitavo e o nono dígito
        //de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        return v
    }

    return (
        <>
        <br/>
        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>CPF/CNPJ <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A1_CGC} type="text" name="A1_CGC" onChange={atualizarFormulario} placeholder="000.000.000-00" />
                <label>digite apenas números</label>
            </div>
            <div className={styles.groupInput}>
                <span>Data de Nascimento <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A1_DTNASC} type="date" name="A1_DTNASC" onChange={atualizarFormulario} placeholder="00/00/0000" />
                <label>digite apenas números</label>
            </div>
        </div>
        <div className={styles.groupInput}>
            <span>Nome <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A1_NOME} type="text" name="A1_NOME" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        { context?.formulario.A1_PESSOA === 'J' &&
        <div className={styles.groupInput}>
            <span>Nome Fantasia <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A1_NREDUZ} type="text" name="A1_NREDUZ" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        }

        {/*  context?.formulario.A1_PESSOA === 'J' &&
        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>Inscrição Estadual <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A1_INSCR} type="text" name="A1_INSCR" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
            <div className={styles.groupInput}>
                <span>Inscrição Municipal <RxDotFilled className={styles.legend} /></span>
                <input  value={context?.formulario.A1_INSCRM} type="text" name="A1_INSCRM" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
        </div>
         */}

        <div className={styles.groupInput}>
            <span>Senha <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A1_ZSENHA} type="password" name="A1_ZSENHA" onChange={atualizarFormulario} placeholder="Senha de Acesso" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        </>

            )

}