import { Children, Dispatch, HTMLProps, ReactPropTypes, SetStateAction } from "react";
import styles from "./styles.module.css"
import { IoCloseOutline } from "react-icons/io5";
import { title } from "process";

type PropTypes = HTMLProps<HTMLDivElement> & {
    setShowModal: Dispatch<SetStateAction<boolean>>
    title: string
    footer?: boolean
    handleNext?: () => void
}

export function Modal({handleNext, footer, setShowModal, title, ...props}: PropTypes) {


    return(

        <div className={styles.modalContainer}>

            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <h3>{title}</h3>
                    <IoCloseOutline onClick={() => setShowModal(false)} className={styles.closeIcon}/>
                </div>
                <div className={styles.modalBody} {...props} />
                {
                    footer &&
                    <div className={styles.modalFooter}>

                        <button onClick={() => setShowModal(false)}>Cancelar</button>
                        <button onClick={() => handleNext && handleNext()}>Confirmar</button>
                    </div>
                }
            </div>  

        </div>
    )
}